export default class WiringManager {
    constructor(wirings) {
        this.wirings = wirings;
    }
    get(id) {
        return this.wirings[id];
    }
    getAll() {
        return this.wirings;
    }
}
